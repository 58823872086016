<template>
    <BT-Blade-Items
        v-if="locationID != null"
        navigation="stock-tracked-items"
        :useServerPagination="true"
        :canChangeDisplay="false"
        :canDelete="false"
        :canCreate="false"
        :hideActions="true"
        dense
        :headers="[
            { text: 'Location', value: 'locationID', navigation: 'locations', itemText: 'locationName' },
            { text: 'Product', value: 'productID', navigation: 'public-products', single: true, itemText: 'productName' },
            { text: 'On Request', value: 'onRequest', textFilter: 'toDisplayNumber' },
            { text: 'To Fulfill', value: 'consigned', textFilter: 'toDisplayNumber' },
            { text: 'In Stock', value: 'inStock', textFilter: 'toDisplayNumber' },
            { text: 'On Order', value: 'onOrder', textFilter: 'toDisplayNumber' },
        ]"
        :params="{ locationID: locationID }">
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Tracked-Item-List',
    data: function() {
        return {
            locationID: null
        }
    },
    async mounted() {
        this.locationID = await this.$selectItem({
            navigation: 'locations',
            itemText: 'locationName',
            itemValue: 'id',
            required: true
        });
    },
    methods: {

    }
}
</script>